import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, of, tap } from 'rxjs';
import { environment } from '../environments/environment.development';
import { JwtResponseDTO } from './models/jwtResponseDTO';
import { IsAuthenticatedDTO } from './models/isAuthenticatedDTO';
import { RefreshTokenResponse } from './models/refreshTokenResponse';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private apiUrl = environment.apiUrlAuth;

  constructor(private http: HttpClient, private router: Router) {}

  login(email: string, password: string): Observable<JwtResponseDTO> {
    return this.http.post<JwtResponseDTO>(`${this.apiUrl}/authenticate`, { email, password }).pipe(
      tap(() => this.router.navigate(['main/home']))
    );
  }

  isAuthenticated(): Observable<IsAuthenticatedDTO> {
    return this.http.get<IsAuthenticatedDTO>(`${this.apiUrl}/authenticated`)
  }

  refreshToken(): Observable<RefreshTokenResponse | boolean> {
    return this.http.post<RefreshTokenResponse>(`${this.apiUrl}/refreshToken`, null).pipe(
      catchError(error => {
        console.error('Erreur lors du rafraîchissement du token', error);
        return of(false); // Renvoyer false en cas d'erreur
      })
    );
  }

  logout(): Observable<unknown> {


    return this.http.post(`${this.apiUrl}/logout`,null, {withCredentials: true}).pipe(

      tap(() => this.router.navigate(['login']))
    )
  }

  getRole(): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiUrl}/authorities`);
  }
}
