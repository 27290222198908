import { CanActivateFn } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { inject } from '@angular/core';
import { map } from 'rxjs';

export const adminGuardGuard: CanActivateFn = () => {
  const authService = inject(AuthenticationService);

  return authService.getRole().pipe(
    map((role) => role.includes('ADMIN'))
  );

};
