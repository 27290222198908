import { Routes } from '@angular/router';
import { adminGuardGuard } from './admin-guard.guard';

export const routes: Routes = [
	{
		path: '',
		loadComponent: () =>
			import('./landing-page/landing-page.component').then(
				m => m.LandingPageComponent
			),
	},
	{
		path: 'main',
		loadComponent: () =>
			import('./main/main-layout/main-layout.component').then(
				m => m.MainLayoutComponent
			),
		children: [
			{
				path: '',
				loadComponent: () =>
					import(
						'./main/hello-from-api/hello-from-api.component'
					).then(m => m.HelloFromApiComponent),
			},
			{
				path: 'dashboard',
				loadComponent: () =>
					import('./main/dashboard/dashboard.component').then(
						m => m.DashboardComponent
					),
			},
			{
				path: 'hello',
				loadComponent: () =>
					import(
						'./main/hello-from-api/hello-from-api.component'
					).then(m => m.HelloFromApiComponent),
			},
			{
				path: 'home',
				loadComponent: () =>
					import('./main/home/home.component').then(
						m => m.HomeComponent
					),
			},
			{
				path: 'tab-player/:id',
				loadComponent: () =>
					import(
						'./main/tablature-player/tablature-player.component'
					).then(m => m.TablatureComponent),
			},
		],
	},
	{
		path: 'admin',
		loadComponent: () =>
			import('./admin/panel-admin/panel-admin.component').then(
				m => m.PanelAdminComponent
			),
		canActivate: [adminGuardGuard],
		children: [
			{
				path: 'crud',
				loadComponent: () =>
					import('./admin/crud-admin/crud-admin.component').then(
						m => m.CrudAdminComponent
					),
				children: [
					{
						path: 'tablature',
						loadComponent: () =>
							import(
								'./admin/tablature-admin/tablature-admin.component'
							).then(m => m.TablatureAdminComponent),
					},
					{
						path: 'artist',
						loadComponent: () =>
							import(
								'./admin/artist-admin/artist-admin.component'
							).then(m => m.ArtistAdminComponent),
					},
					{
						path: 'album',
						loadComponent: () =>
							import(
								'./admin/album-admin/album-admin.component'
							).then(m => m.AlbumAdminComponent),
					},
					{
						path: 'genre',
						loadComponent: () =>
							import(
								'./admin/genre-admin/genre-admin.component'
							).then(m => m.GenreAdminComponent),
					},
					{
						path: 'tag',
						loadComponent: () =>
							import(
								'./admin/tag-admin/tag-admin.component'
							).then(m => m.TagAdminComponent),
					},
				],
			},
		],
	},
	{
		path: 'login',
		loadComponent: () =>
			import('./main/login/login.component').then(m => m.LoginComponent),
	},
	{
		path: '**',
		loadComponent: () =>
			import('./error-page/error-page.component').then(
				m => m.ErrorPageComponent
			),
	},
];
