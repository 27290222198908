import { Router } from '@angular/router';
import { HttpInterceptorFn} from '@angular/common/http';
import { inject, } from '@angular/core';
import { catchError,switchMap} from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AuthenticationService } from './authentication.service';


export const AuthInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthenticationService);
  const router = inject(Router);
  const clonedRequest = req.clone({
    withCredentials: true
  });
  
  if(req.url.includes('authenticate') && !req.url.includes('authenticated')) {
    return next(clonedRequest);
  }

  return next(clonedRequest).pipe(
    catchError((err) => {
      if (err.status == 401) {
        return authService.refreshToken().pipe(
          switchMap(() => {
            return next(clonedRequest);
          }),
          catchError(() => {
              return authService.logout().pipe(
                switchMap(() => {
                  router.navigate(['/main/login']);
                  return next(clonedRequest);
                })
              )

          })
        );
      }
      return throwError(() => err);
    })
  );
}
