import { HttpClientModule } from '@angular/common/http';
import { Component} from '@angular/core';
import {  Router, RouterModule } from '@angular/router';
import { VerticalNavbarComponent } from './main/vertical-navbar/vertical-navbar.component';
import {  } from 'flowbite';

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [ HttpClientModule, RouterModule, VerticalNavbarComponent],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
})
export class AppComponent {
  constructor(private router: Router) {}
}
